import { RouteRecordRaw } from "vue-router";

const routes: RouteRecordRaw[] = [
    {
        path: "/",
        component: () => import("@/layouts/MainLayout.vue"),
        children: [{ path: "", component: () => import("./components/StartPage.vue") }],
    },
];

export default routes;
