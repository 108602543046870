import { RouteRecordRaw } from "vue-router";

const routes: RouteRecordRaw[] = [
    {
        path: "/giftcard",
        component: () => import("@/layouts/MainLayout.vue"),
        children: [{ path: "", component: () => import("./components/GiftCardPage.vue") }],
    },
];

export default routes;
