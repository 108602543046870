import { PublicClientApplication } from '@azure/msal-browser';

const msalConfig = {
  auth: {
    clientId: 'a18d7e22-f50d-4a0a-9627-84ee596c0391',
    authority: 'https://login.microsoftonline.com/1a268335-c833-4c13-bad8-a9f00b90fd49',
    redirectUri: '/', // Must be registered as a SPA redirectURI on your app registration
    postLogoutRedirectUri: '/' // Must be registered as a SPA redirectURI on your app registrationregistration
  },
  cache: {
    cacheLocation: 'localStorage' // Options are localStorage, sessionStorage, memoryStorage
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);