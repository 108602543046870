import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";

import resourceManagementRoutes from "@/modules/ResourceManagement/routes";
import adminRoutes from "@/modules/Admin/routes";
import giftCardRoutes from "@/modules/GiftCard/routes";
import startRoutes from "@/modules/Start/routes";

const routes: Array<RouteRecordRaw> = [
    ...startRoutes,
    ...adminRoutes,
    ...giftCardRoutes,
    ...resourceManagementRoutes,
    {
        path: "/:catchAll(.*)*",
        component: () => import("@/layouts/MainLayout.vue"),
        children: [{ path: "", component: () => import("@/modules/Start/components/StartPage.vue") }],
    },
];

const router = createRouter({
    history: createWebHashHistory("/"),
    routes,
    scrollBehavior() {
        return { top: 0 };
    },
});

export default router;
