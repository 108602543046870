/**NOTE: This file and any imports in it will not be included in the production build */

import i18n from "@/i18n";
import { AuthenticationResult, EventType } from "@azure/msal-browser";
import { createApp } from "vue";
import { VueQueryPlugin } from "vue-query";
import { pinia } from "~/store";
import App from "./App.vue";
import { msalInstance } from "./config/authConfig";
import eventbusPlugin from "./plugins/eventbus";
import { msalPlugin } from "./plugins/msalPlugin";
import router from "./router";

const app = createApp(App);

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}
msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
    }
});

app.use(pinia);
app.use(i18n);
app.use(router);
app.use(eventbusPlugin);
app.use(VueQueryPlugin);
app.use(msalPlugin, msalInstance);
app.mount("#app");
