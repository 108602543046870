import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    width: "0",
    height: "0",
    style: {"display":"none"},
    innerHTML: _ctx.svgSprite
  }, null, 8, _hoisted_1))
}