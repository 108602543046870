import { eventBusKey } from "@/types/symbols/Eventbus";
import { Ref, inject, ref } from "vue";

const dialogKey = "display-dialog";

type DialogMessage = {
    type?: string;
    title?: string;
    message?: string;
    cancelText?: string;
    confirmText?: string;
    callback?: () => Promise<unknown>;
};

const useDialog = (dialogRef?: Ref<any>) => {
    const eventBus = inject(eventBusKey);

    const data = ref<DialogMessage>();

    eventBus?.on(dialogKey, (event) => {
        const e = event as DialogMessage;
        data.value = e;
        if(dialogRef?.value?.open){
            dialogRef.value.open();
        }
    });

    const displayDialog = (message: DialogMessage, timeout = 10) => {
        setTimeout(() => {
            eventBus?.emit(dialogKey, message);
        }, timeout);
    };

    return {
        displayDialog,
        data,
    };
};

export default useDialog;
